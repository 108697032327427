<template>
  <div
    v-if="allDirectRetailers && allDirectRetailers.length"
    class="d-none d-sm-block wishlist-width"
  >
    <b-button
      v-if="selectedRetailer"
      variant="custom-secondary"
      @click="onExitRetailerView"
    >
      Exit Retailer View
    </b-button>
    <b-nav-item-dropdown
      v-else
      id="myNavItemDropdown"
      ref="mydrop"
      class="w-100"
      menu-class="dropdown-menu-media"
      no-caret
      right
    >
      <template #button-content>
        <div class="btn btn-custom-secondary">
          <span class="ml-50 d-block text-truncate text-ellipsis">View as Retailer</span>
        </div>
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            My Direct Retailers
          </h4>
          <b-badge
            v-if="filteredRetailers.length"
            pill
            variant="light-primary"
          >
            {{ filteredRetailers.length }} retailer{{ filteredRetailers.length !== 1 ? 's' : '' }}
          </b-badge>
          <b-badge
            v-else
            pill
            variant="light-primary"
          >
            0 retailers
          </b-badge>
        </div>
      </li>
      <div class="position-relative col-md-12 p-2">
        <b-form-input
          v-model="search"
          placeholder="Search Retailer"
          class="search-product"
        />
        <feather-icon
          icon="SearchIcon"
          size="18"
          class="wallet-search-icon"
        />
      </div>
      <vue-perfect-scrollbar
        v-if="filteredRetailers.length"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <b-media
          v-for="retailer in filteredRetailers"
          :key="retailer.walletRuleId"
          :class="(selectedRetailer && retailer.retailerId === selectedRetailer.retailerId) ? 'active' : ''"
        >
          <div
            class="media-heading cursor-pointer"
            @click="onSelectRetailer(retailer)"
          >
            <h6 class="cart-item-title">
              <div class="text-body">
                {{ retailer.entityName }} {{ retailer.retailerBrandIdentity && `(${retailer.retailerBrandIdentity})` }} - {{ retailer.email }}
              </div>
            </h6>
          </div>
        </b-media>
      </vue-perfect-scrollbar>
      <div
        v-else
        class="text-muted p-2"
      >
        Retailers not found
      </div>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  VBTooltip,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import UserRoleMixinVue from '@/views/apps/UserRoleMixin.vue'
import { UPDATE_SELECTED_DIRECT_RETAILER } from '@/store/modules/shop.module'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { debounce } from '@/@core/utils/utils'
import { FETCH_DIRECT_RETAILERS } from '@/store/modules/connections.module'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    VuePerfectScrollbar,
    BButton,
    BFormInput,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixinVue],
  props: {
    allDirectRetailer: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      allDirectRetailers: [],
      directRetailers: '',
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      selectedRetailer: null,
      isOpen: false,
      search: '',
    }
  },
  computed: {
    filteredRetailers() {
      this.debouncedTrackSearch(this.search)
      const searchTerm = this.search?.trim().toLowerCase()
      return this.allDirectRetailers.filter(retailer => retailer.entityName?.toLowerCase().includes(searchTerm)
        || retailer.retailerBrandIdentity?.toLowerCase().includes(searchTerm)
        || retailer.email?.toLowerCase().includes(searchTerm))
    },
    totalAmount() {
      let total = 0
      this.items.forEach(i => {
        total += i.price
      })
      return total
    },
  },
  mounted() {
    this.getDirectRetailer()
  },
  created() {
    this.debouncedTrackSearch = debounce(this.trackSearch)
  },
  methods: {
    getDirectRetailer() {
      this.$store.dispatch(FETCH_DIRECT_RETAILERS).then(
        res => {
          this.allDirectRetailers = res
        },
      )
    },
    onSelectRetailer(retailer) {
      this.selectedRetailer = retailer
      this.$root.$refs.shopItems?.setDirectRetailerInfo(retailer.retailerId)
      this.$store.commit(UPDATE_SELECTED_DIRECT_RETAILER, retailer)
      this.search = ''
      this.$refs.mydrop.hide()
    },
    onExitRetailerView() {
      this.selectedRetailer = null
      this.$root.$refs.shopItems?.setDirectRetailerInfo()
      this.$store.commit(UPDATE_SELECTED_DIRECT_RETAILER, null)
    },
    resetSearch() {},
    trackSearch(searchTerm) {
      analytics.track(constants.TRACKS.ACTIONS.USER_SEARCH_RETAILERS, { searchTerm })
    },
  },
}
</script>

<style lang="scss" scoped>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart {
  .media-heading {
    width: 25rem;
  }
}
.wishlist-width {
  max-width: 250px;
}
.search-wrapper {
  position: relative;
}
.search-product {
  width: 100%;
  padding-right: 2.5rem;
  box-sizing: border-box;
}
.wallet-search-icon {
  position: absolute;
  top: 50%;
  right: 2.2rem;
  transform: translateY(-50%);
  pointer-events: none;
}
.text-muted {
  color: #6c757d;
}
</style>
